@import '../common/Modal/ModalExports.scss';

.contactUsModalOverlay {
	@include overlay;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contactUsModal {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 50px 30px 40px 30px;
	box-sizing: border-box;
	max-width: 560px;
	width: 100%;
	background-color: #f6f7f9;
	border-radius: 10px;
	overflow: auto;

	@media (max-width: 570px) {
		max-height: 500px;
	}
}

.contactUsTitle {
	font-size: 41px;
	font-weight: 700;
	color: #000000;
	padding-bottom: 10px;
	text-transform: uppercase;
	text-align: center;

	@media (max-width: 768px) {
		font-size: 28px;
	}
}

.contactUsSubTitle {
	font-size: 17px;
	font-family: 'MuseoSans-700', sans-serif;
	color: #586069;
}

.contactUsInput {
	background: #ffffff;
	border: 2px solid transparent;
	box-sizing: border-box;
	border-radius: 5px;
	width: 240px;
	font-size: 13px;
	padding: 0 15px;
	height: 40px;
	margin-top: 20px;
	outline: none;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
	-webkit-appearance: none;
	margin-right: 10px;

	&:last-child {
		margin-right: 0;
	}

	&::placeholder {
		color: #8e9297;
	}

	&:focus {
		border: 2px solid #7e57c2;
	}

	@media (max-width: 569px) {
		width: 100%;
	}
}

.contactInputFile {
	display: flex;
	align-items: center;
	color: #959da5;
	background: #ffffff;
	border: 2px solid transparent;
	box-sizing: border-box;
	border-radius: 5px;
	width: 240px;
	font-size: 13px;
	padding: 0 15px;
	height: 40px;
	margin-top: 20px;
	outline: none;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
	-webkit-appearance: none;
	margin-right: 10px;

	&:last-child {
		margin-right: 0;
	}

	&::placeholder {
		color: #8e9297;
	}

	&:focus {
		border: 2px solid #7e57c2;
	}
}

.contactUsInputError {
	background: #ffffff;
	border: 2px solid #f32121;
	box-sizing: border-box;
	border-radius: 5px;
	width: 240px;
	font-size: 13px;
	padding: 0 15px;
	height: 40px;
	margin-top: 20px;
	outline: none;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
	-webkit-appearance: none;
	margin-right: 10px;

	&:last-child {
		margin-right: 0;
	}
}

.contactUsTextArea {
	background: #ffffff;
	border: 2px solid transparent;
	border-radius: 5px;
	margin-right: 30px;
	padding: 15px;
	margin-top: 20px;
	width: calc(100% - 40px);
	font-size: 13px;
	font: inherit;
	resize: none;
	outline: none;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
	-webkit-appearance: none;

	&::placeholder {
		color: #586069;
		font-size: 13px;
	}

	&:focus {
		border: 2px solid #7e57c2;
	}
}

.contactUsButton {
	text-transform: uppercase;
	background-color: #749a3f;
	border: none;
	font-weight: 700;
	border-radius: 5px;
	margin-top: 20px;
	padding: 16px 45px;
	color: #ffffff;
	width: 100%;
	outline: none;
	cursor: pointer;
}

.contactUsModalFooter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 0 30px;
	box-sizing: border-box;
	max-width: 560px;
	width: 100%;
	height: 80px;
	background-color: #ffffff;
	border-radius: 0 0 10px 10px;

	.infoBackgr {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
}

.contactSocialIconsWrapper {
	display: flex;
	flex-direction: row;

	a {
		margin: 0 5px;

		svg {
			width: 24px;
			height: 28px;
			fill: #959da5;

			&:hover {
				fill: #66bb6a;
			}
		}
	}
}

.closeContactUsModal {
	position: absolute;
	display: flex;
	align-items: center;
	top: 0px;
	right: 0px;

	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;

	color: #959da5;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 10px;

	svg {
		width: 32px;
		height: 32px;
		margin-left: -7px;
		margin-bottom: 1px;

		stroke: #959da5;
	}

	transition: all 0.2s ease-in-out;
	&:hover {
		opacity: 0.7;
	}
}

.contactUsEmailStatus {
	padding: 20px 0 0;
	height: 50px;
	box-sizing: border-box;
}

.contactUsSuccess {
	color: #66bb6a;
}

.contactUsError {
	color: #f32121;
}

.contactUsInputCalendarWrapper {
	position: relative;
	width: 240px;
	margin: 20px 10px 0 0 ;

	svg {
		position: absolute;

		cursor: pointer;
		top: 10px;
		right: 10px;
	}

	@media (max-width: 569px) {
		width: 100%;
	}
}

.contactUsCalendar {
	position: absolute;
	max-width: 270px;
	background-color: #ffffff;
	padding: 5px;
	box-sizing: border-box;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
	-webkit-appearance: none;
	border-radius: 8px;
	z-index: 2;
}

.fieldTitle {
	font-size: 17px;
	font-family: 'MuseoSans-700', sans-serif;
	color: #24292e;
	margin-top: 15px;
}

.serviceSelectorWrapper {
	position: relative;
	display: flex;
	width: 270px;
	cursor: pointer;
	margin: 20px 10px 0 0;

	@media (max-width: 569px) {
		width: 100%;
	}
}

.openedLocationSelectorWrapper {
	position: relative;
	display: flex;
	width: 270px;
	cursor: pointer;
	margin: 20px 10px 0 0;

	.serviceSelectorInit {
		border-radius: 8px 8px 0 0;
	}

	.serviceSelector {
		border-radius: 8px 8px 0 0;
	}

	@media (max-width: 736px) {
		width: 100%;
	}
}

.serviceSelectorErr {
	display: flex;
	align-items: center;
	border: 2px solid #f32121;;
	background-color: white;
	border-radius: 8px;
	justify-content: space-between;
	text-transform: capitalize;
	padding: 0 15px;
	font-size: 14px;
	height: 40px;
	width: 210px;
	color: #959da5;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
	-webkit-appearance: none;

	@media (max-width: 569px) {
		width: 100%;
	}

	svg {
		width: 10px;
		fill: #000;
	}
}

.serviceSelector {
	display: flex;
	align-items: center;
	border: 0.5px solid #f5f5f5;
	border-radius: 8px;
	justify-content: space-between;
	text-transform: capitalize;
	padding: 0 15px;
	font-size: 14px;
	height: 40px;
	width: 210px;
	background-color: white;
	color: #959da5;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
	-webkit-appearance: none;

	@media (max-width: 569px) {
		width: 100%;
	}

	svg {
		width: 10px;
		fill: #000;
	}
}

.openService {
	transform: rotate(270deg);
}

.closedService {
	transform: rotate(90deg);
}

.serviceSelectorList {
	position: absolute;
	text-transform: capitalize;
	top: 40px;
	left: 0;
	right: 0;
	padding: 0px 20px 20px;
	background-color: #ffffff;
	border-radius: 0 0 10px 10px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
	-webkit-appearance: none;
	z-index: 1;
	width: 201px;


	.service {
		padding: 10px 0;
		color: #586069;
		cursor: pointer;

		&:hover {
			color: #000;
		}
	}

	@media (max-width: 736px) {
		width: initial;
	}
}

.contactUsCalendarInput {
	background: #ffffff;
	border: 2px solid transparent;
	box-sizing: border-box;
	border-radius: 5px;
	width: 240px;
	font-size: 13px;
	padding: 0 15px;
	height: 40px;
	outline: none;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
	-webkit-appearance: none;

	&:last-child {
		margin-right: 0;
	}

	&::placeholder {
		color: #8e9297;
	}

	&:focus {
		border: 2px solid #7e57c2;
	}

	@media (max-width: 569px) {
		width: 100%;
	}
}

.inputsWrapper {
	display: flex;

	@media (max-width: 569px) {
		flex-direction: column;
	}
}

.locationsInputWrapper {
	position: relative;

	@media (max-width: 569px) {
		width: calc(100% - 30px);
	}
}

.locationsInputButton {
	position: absolute;
    width: 20px;
    right: 10px;
    top: 1px;
    padding: 8px 0 8px 3px;
    background: white;

	@media (max-width: 569px) {
		right: -20px;
	}
}