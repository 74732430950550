@import '../../common/Modal/ModalExports.scss';

.menuModalOverlay {
	@include overlay;

	background-color: #749a3f;
}

.menuModalContent {
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.menuLinksWrapper {
	display: flex;
	flex-direction: column;
	overflow: auto;
	padding: 40px 0 0 0;
}

.menuLinks {
	display: flex;
	justify-content: space-between;
}

.menuLink {
	display: flex;
	align-items: center;
	font-size: 18px;
	color: #ffffff;
	text-decoration: none;
	font-weight: 500;
	padding: 5px 0;
	transition: all 0.2s ease-in-out;

	svg {
		margin-left: 25px;
		height: 14px;
		fill: white;
	}

	&:hover {
		color: #24292e;

		svg {
			fill: #24292e;
		}
	}
}

.menuLinksContainer {
	position: relative;
}

.menuLinkOpened {
	padding-left: 20px;
}

.menuExpertiseLinks {
	margin-left: 40px;
}

.menuTitle {
	text-align: center;
	font-size: 32px;
	padding-bottom: 20px;
	text-transform: uppercase;
	font-family: 'MuseoSans-700', sans-serif;
	color: #ffffff;
}

.menuCloseWrapper {
	position: absolute;
	display: inline-block;
	top: 10px;
	right: 10px;
	z-index: 1;
	cursor: pointer;

	svg {
		stroke: #ffffff;
	}
}

.menuBackgraundWrapper {
	position: absolute;
	top: 0;
	z-index: -1;
	display: flex;
	width: 100%;
}

.menuModalVector {
	display: flex;
	height: 100%;
	fill: #a5d6a74d;
}

.menuModalSocialWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;

	a {
		&:last-child {
			svg {
				margin-right: 0;
			}
		}
	}
}

.mobileSocialWrapper {
	svg {
		margin-right: 10px;
		fill: white;
	}
}

.menuModalCovidWrapper {
	display: block;
	margin-bottom: 40px;
}

.menuModalCovidButton {
	img {
		width: 80px;
	}
}

.openMenu {
	transform: rotate(90deg);
}

.languageContainerMob {
	position: relative;
	font-family: 'MuseoSans-500', sans-serif;
	font-size: 18px;
	color: #fff;
	margin-right: 20px;
	padding-bottom: 50px;
	cursor: pointer;

	svg {
		stroke: #fff;
		margin-left: 15px;
		height: 10px;

		&:hover {
			opacity: 0.8;
		}
	}

	&:hover {
		opacity: 0.8;
	}
}

.languageContainerSticky {
	position: relative;
	font-family: 'MuseoSans-500', sans-serif;
	font-size: 18px;
	color: #000;
	margin-right: 20px;
	cursor: pointer;

	svg {
		stroke: #000;
		margin-left: 15px;
		height: 10px;

		&:hover {
			opacity: 0.8;
		}
	}

	&:hover {
		opacity: 0.8;
	}
}

.languageDropdown {
	position: absolute;
}

.languageSelectorOpened {
	svg {
		transform: rotate(180deg);
	}
}

.languageDropdown {
	padding: 10px 0;
}

.languageDropdownValue {
	&:hover {
		opacity: 0.8;
	}
}
