.covidWrapper {
	position: absolute;
	right: -11px;
	top: -11px;
	width: 700px;
	z-index: 20;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	transition: 200ms;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	-webkit-appearance: none;

	@media (max-width: 930px) {
		flex-wrap: wrap;
		margin: 40px;
		width: auto;
	}
}

.openedInfoImage {
	position: absolute;
	right: 11px;
	top: 11px;
	max-width: 80px;
	max-height: 48px;

	@media (max-width: 430px) {
		position: static;
		padding-bottom: 10px;
	}
}

.covidContent {
	display: flex;
	flex-direction: column;
	padding: 45px;
	line-height: 1.5;
	font-family: 'SegorUI', sans-serif;

	span {
		font-size: 14px;
		color: #959da5;

		a {
			color: #66bb6a;
		}
	}

	@media (max-width: 768px) {
		font-size: 11px;
		padding: 25px;
		p {
			margin: 0;
		}
	}

	@media (max-width: 430px) {
		align-items: center;
		font-size: 10px;
		line-height: 1.3;
	}
}

.covidTitle {
	font-size: 18px;
	padding-bottom: 20px;
	color: #7c984c;
	font-family: 'MuseoSans-900', sans-serif;
	text-transform: uppercase;
}

.covidButton {
	width: 144px;
	height: 50px;
	margin: 15px 0 0 0;
	background-color: #7c984c;
	border-radius: 8px;
	border: none;
	outline: none;
	color: #ffffff;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 600;
	cursor: pointer;

	&:hover {
		box-shadow: 0px 0px 15px rgba(124, 152, 76, 0.54);
		-webkit-appearance: none;
	}

	&:active {
		background: #749a3f;
	}
}
