.footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 30px;
	background-color: #f8f9f7;
}

.footerLinks {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;

	@media (max-width: 800px) {
		flex-direction: column;
		max-width: 320px;
	}
}

.footerLink {
	font-weight: 300;
	font-size: 11px;
	text-decoration: none;
	color: #24292e;
	margin-top: 10px;
	padding: 0 15px;
	border-right: 1px solid #24292e;
	font-family: 'SegorUI', sans-serif;
	cursor: pointer;

	&:hover {
		text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.55);
	}

	&:last-child {
		border-right: none;
	}

	@media (max-width: 375px) {
		&:first-child {
			padding: 0 5px 0 0;
		}

		&:last-child {
			padding: 0 0 0 5px;
		}
	}
}

.footerSocialIconsWrapper {
	margin-top: 20px;
	@media (max-width: 425px) {
		display: none;
	}

	a {
		margin: 30px 5px 55px;

		svg {
			width: 24px;
			height: 28px;
			fill: #24292e;
		}
	}
}

.mobileFooterSocialIconsWrapper {
	display: none;
	margin-top: 20px;

	@media (max-width: 425px) {
		display: block;
	}

	a {
		margin: 30px 5px 55px;

		svg {
			width: 24px;
			height: 28px;
			fill: #24292e;
		}
	}
}

.footerEndCopyrightWrapper {
	width: 100%;

	margin-top: 40px;
}

.footerEndCopyright {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1200px;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 20px;

	@media (max-width: 450px) {
		padding: 20px 15px;
	}
}

.footerCopyright {
	font-size: 11px;
	color: #24292e;

	@media (max-width: 470px) {
		span {
			display: none;
		}
	}
}

.footerPhone {
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 24px;
	font-family: 'SegorUI', sans-serif;
	color: #161c16;

	text-decoration: none;
	cursor: pointer;

	svg {
		margin-right: 10px;
	}
}

.footerPhoneWithSocial {
	display: flex;
	flex-direction: column;
	font-size: 14px;
	line-height: 24px;
	font-family: 'SegorUI', sans-serif;
	color: #161c16;
	margin: 10px;
}

.footerEmail {
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 24px;
	font-family: 'SegorUI', sans-serif;
	color: #161c16;
	margin: 10px;

	text-decoration: none;
	cursor: pointer;

	svg {
		margin-right: 10px;
	}
}

.footerLocations {
	display: flex;
	font-size: 14px;
	line-height: 24px;
	font-family: 'SegorUI', sans-serif;
	color: #161c16;
	margin: 10px;

	a {
		display: flex;
		text-decoration: none;
		color: inherit;

	}

	svg {
		margin-right: 10px;
	}
}

.footerAppButtons {
	display: flex;
	flex-direction: column;
	font-size: 14px;
	line-height: 24px;
	font-family: 'SegorUI', sans-serif;
	color: #161c16;
	margin: 10px;
}

.footerAppButtonsWrapper {
	margin-top: 15px;

	a {
		&:first-child {
			margin-right: 20px;
		}
	}
}

.footerLogoIcon {
	width: 308px;

	margin-bottom: 40px;
}
